import { useEffect, useState } from 'react';
import { useAppSelector } from '../../services/hooks';
import Typography from '@mui/material/Typography';

import './voyage-details.css';

const HOUR_TO_MS = 3600000

const VoyageDetails = () => {

    const [arrivalTime, setArrivalTime] = useState<Date>();
    const [startTime, setStartTime] = useState<Date>();

    const voyageState = useAppSelector(store => store.voyage);

    useEffect(() => {
        if (voyageState.duration && voyageState.duration > 0) {
            let voyageDurationInMs = voyageState.duration * HOUR_TO_MS;
            let startTime = Date.now();
            let arrivalTime = new Date(startTime + voyageDurationInMs);
            setStartTime(new Date(startTime))
            setArrivalTime(arrivalTime);
        }
    }, [voyageState.duration])

    return (<div className="voyage-listing" >
        <h2>Voyage</h2>
        <div className="details-container">
            <div className="voyage-duration">
                {
                    !!startTime && !!arrivalTime &&
                    <>
                        <Typography variant={'body1'}> Start Time:  {startTime.toLocaleString()}</Typography>
                        <Typography variant={'body1'}> Arrival Time:  {arrivalTime.toLocaleString()}</Typography>
                    </>
                }
            </div>
            {
                (voyageState.ports.length > 0) &&
                <div className="ports-list">
                    {voyageState.ports.map(port => <Typography variant={'body2'} key={port.name}>{port.name}</Typography>)}
                </div>
            }
        </div>
    </div >)
}

export default VoyageDetails;
