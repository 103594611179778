import { AnyAction } from "redux"
import { Port } from "./ports-reducer"
import * as lodash from 'lodash'
import { getTimeOfTotalDistance } from "../utils/voyage-distance-calc"
/**
 * This file controls the state of the "voyage" data.  A voyage consists of 0 or more
 * stops in a port.  You should calculate the total duration of the voyage as ports
 * are added by using the lat/lon of the port location and the VESSEL_SPEED_KNPH constant
 * for speed.  For the purpose of calculating distance, assume that the vessel can travel
 * in a straight line between ports (pretend it is a blimp).
 */


//Vessel speed in natical miles per hour
export const VESSEL_SPEED_KNPH = 10.0

interface VoyageState {
    //The total duration of the voyage in milliseconds
    duration: number,

    //ordered list of ports the vessel will stop at
    ports: Port[]
}

 
const initialState: VoyageState = {
    duration: 0,
    ports: []
 }

 
 export function voyageReducer(state = initialState, action: AnyAction){
     //using lodash for a deep clone to avid the redux error "A state mutation was detected inside a dispatch"
     // I could have also use JSON.parse(JSON.stringify(state)) . But this might fail with large objects. 
     let newState= lodash.cloneDeep(state)
 
    switch (action.type) {
        case 'addPortToVoyage':
            let voyageDuration = 0;
            newState.ports.push(action.payload)
            if(newState.ports.length > 1) {
                voyageDuration = getTimeOfTotalDistance(newState.ports) / VESSEL_SPEED_KNPH;
            }
            newState.duration = voyageDuration;
            return newState;
        case 'removePortFromVoyage':
            let portIndex = newState.ports.findIndex(item=>item.name===action.payload.name)
            if(portIndex !== -1){
                newState.ports.splice(portIndex,1)
            }
            return newState;
    
        default:
            return newState;
    }
 }

 export function addPort(port: Port){}
 export function removePort(port: Port){}
 export function movePort(port: Port, newPosition: number){}
 