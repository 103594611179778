import { Port } from "../services/ports-reducer";

interface Coordinates {
  lat: number;
  lng: number;
}
const getDistanceBetweenTwoPoints = (
  point1: Coordinates,
  point2: Coordinates
) => {
  if (point1.lat === point2.lat && point1.lng === point2.lng) {
    return 0;
  } else {
    let radlat1 = (Math.PI * point1.lat) / 180;
    let radlat2 = (Math.PI * point2.lat) / 180;
    let theta = point1.lng - point2.lng;
    let radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 0.8684;
    return dist;
  }
};

export const getTimeOfTotalDistance = (ports: Port[]) => {
  const coordinates = ports.map((port) => {
    return { lat: port.lat, lng: port.lng };
  });

  let totalDistance = 0;

  if (!coordinates) {
    return 0;
  }

  if (coordinates.length < 2) {
    return 0;
  }
  for (let i = 0; i < coordinates.length - 1; i++) {
    if (
      !coordinates[i].lng ||
      !coordinates[i].lat ||
      !coordinates[i + 1].lng ||
      !coordinates[i + 1].lat
    ) {
      totalDistance = totalDistance;
    }
    totalDistance =
      totalDistance +
      getDistanceBetweenTwoPoints(coordinates[i], coordinates[i + 1]);
  }

  return totalDistance;
};
