/**
 * Redux store for the Ports data.  Ports represent a geographical location
 * where a vessel may travel to
 */

import { AnyAction } from "redux"

export interface Port {
    uncode: string,
    name: string,
    lat: number,
    lng: number
}

interface PortsState {
    count: number,
    offset: number,
    ports: Port[]
}

const initialState: PortsState = {
    count: 0,
    offset: 0,
    ports: []
}

/**
 * TODO: implement the reducer function to manage the list of ports
 * within the redux store
 * @param state 
 * @param action 
 * @returns PortsState
 */
export function portsReducer(state = initialState, action: AnyAction): PortsState{
    switch (action.type) {
        case 'addFetchedPorts':
            return state = action.payload;
    
        default:
            break;
    }

    return state
}

/**
 * TODO: implement the fetchPorts function to retrieve the ports data
 * from the API.  Note: the API returns at most 10 ports per API call
 * so you may need multiple calls to fetch all the data
 * @param offset: where to start pulling from
 */
export async function fetchPorts(){
    let finalPorts: PortsState = {...initialState};

    let shouldFetchMore =true;
    let offset = 0;
    let totalPorts = 0;
    let fetchedPorts: {data:Port[], offset: number, total: number} | null;

    do {
        fetchedPorts = await fetch(`https://8u9tblsay0.execute-api.us-east-1.amazonaws.com/default/zn-frontend-challenge-port-service?offset=${offset}`)
    .then(result=> {
        if(result && result.status === 200 ){
            return result.json()
        }
    })
    .then((res:{data:Port[], offset: number, total: number})=>{
        if(!!res) {
            return res;
        }
        return null;
    })
    .catch(err=>{
        console.log('Something went wrong when fetching the ports', err);
        return null;
    })

    if (fetchedPorts === null) {
        shouldFetchMore=false;
    } else {
        if (fetchedPorts.data.length>0) {
            finalPorts.ports = finalPorts.ports.length > 0 ? [...finalPorts.ports, ...fetchedPorts.data]:[...fetchedPorts.data]
            finalPorts.count += fetchedPorts.data.length
            offset +=10
        }
    }
    } while (shouldFetchMore && totalPorts !== finalPorts.count);

    return finalPorts;

}



