import React, { useEffect, useState } from 'react'
import RouteMap from "./route-map"
import { fetchPorts, Port } from '../services/ports-reducer'
import { useAppDispatch, useAppSelector } from '../services/hooks'
import VoyageDetails from './voyage-details/voyage-details'
import "./voyage-planner.css"
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme-provider'
import Button from '@mui/material/Button'

interface VoyagePlannerParams{

}

function VoyagePlanner(params: VoyagePlannerParams){

    const dispatch = useAppDispatch();
    const portsState = useAppSelector(store=>store.ports)
    const voyageState = useAppSelector(store=>store.voyage)
    const [selectedPort, setSelectedPort] = useState<string>();

    useEffect(()=> {
        const asyncFetchPorts = async ()=> {
            let availablePorts = await fetchPorts();
            dispatch({type:'addFetchedPorts', payload:availablePorts})
        }
        asyncFetchPorts()
    },[])

    const portSelectionHandler= (event: React.SyntheticEvent, value: {label:string} | null)=>{
        if(!!value && value.label) {
            setSelectedPort(value.label)
        }
    }

    const addPortToVoyageHandler= ()=>{
        let newPort = portsState.ports.find(port=>port.name === selectedPort)
        if(newPort) {
            let {uncode,name, lat, lng} = newPort
            dispatch({type:'addPortToVoyage', payload: {uncode, name, lat, lng} as Port})
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="voyage-planner-container">
                <div className="voyage-route-container">
                    <h2>Route Planner</h2>
                    {portsState.ports.length !== 0 ?
                        <div className="port-locator">
                            <Autocomplete
                                disablePortal
                                id="port-selector"
                                className={"port-select"}
                                options={portsState.ports.map(port => ({ label: port.name }))}
                                size={'small'}
                                renderInput={(params) => <TextField {...params} label="Ports" />}
                                onChange={portSelectionHandler}
                                multiple={false}
                                value={selectedPort? {label:selectedPort}: null}
                                isOptionEqualToValue={(option, value) =>option.label === value.label}
                                
                            />
                            <Button variant="outlined" onClick={addPortToVoyageHandler} disabled={!selectedPort}>+</Button>
                        </div> :
                        <p>Loading ports...</p>
                    }
                    <VoyageDetails />
                </div>
                <div className="voyage-map-container">
                    <h2>Route Map</h2>
                    <RouteMap ports={voyageState.ports} />
                </div>

            </div>
        </ThemeProvider>
    )
}
export default VoyagePlanner
