import React, {useState, useEffect} from "react"
import { Port } from '../services/ports-reducer'
import { Loader } from "@googlemaps/js-api-loader"

declare global {
    interface Window {
        initMap: () => void;
    }
}

interface RouteMapParams{
    ports: Port[];
}

const MARKER_LABELS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function RouteMap({ports}: RouteMapParams){
    const [theMap, setTheMap] = useState<google.maps.Map>()

    const loader = new Loader({
        apiKey: "AIzaSyDckokR5ozbjFB7hC0yip1S5mbPYcgoQv8",
        version: "weekly"
    })

    useEffect(() => {

        loader
            .load()
            .then((google) => {
                const mapOptions = {
                    center: {
                        lat: 0,
                        lng: 0
                    },
                    zoom: 2,
                    scaleControl: true
                }
                const map = new google.maps.Map(document.getElementById("route-map") as HTMLElement, mapOptions);

                if (ports.length > 0) {
                    ports.forEach((port, index) => {
                        const marker = new google.maps.Marker({
                            position: { lat: port.lat, lng: port.lng },
                            label: MARKER_LABELS[index],
                            map: map,
                        });
                    })
                }
                if (ports.length > 1) {
                    ports.forEach((port, index, array) => {
                        if (index < (array.length - 1)) {
                            let nextPoint = { ...array[index + 1] }
                            let drawLine = new google.maps.Polyline({ path: [{ lat: port.lat, lng: port.lng }, { lat: nextPoint.lat, lng: nextPoint.lng }], map: map })
                        }
                    })
                }
                setTheMap(map);
            })
    }, [ports]);

    return (
    <div id="map-container">        
        <div id="route-map"></div>    
    </div>
    )
}

export default RouteMap;
