import React from 'react';
import { Provider } from 'react-redux';
import VoyagePlanner from './features/voyage-planner'
import { store } from './services/store';
import './App.css';

function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <header className="App-header">
        <img src={"/zero_north_logo_green.png"} className="App-logo" alt="ZeroNorth" />
      </header>
      <VoyagePlanner/>
    </div>
    </Provider>
  );
}

export default App;
